import DatePicker from '@rio-cloud/rio-uikit/components/datepicker/DatePicker';
import ButtonDropdown from '@rio-cloud/rio-uikit/components/dropdown/ButtonDropdown';
import { MenuItemProps } from '@rio-cloud/rio-uikit/components/menuItems/MenuItem';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { format, subYears } from 'date-fns';
import { useIntl } from 'react-intl';

interface CustomDateFilterProps {
    onDateChange: (dateFrom: string | undefined, dateTo: string | undefined) => void;
}

const dateRanges = [
    { labelI18n: 'intl-msg:maintenanceHistory.filter.lastYear', years: 1, default: false },
    { labelI18n: 'intl-msg:maintenanceHistory.filter.lastTwoYears', years: 2, default: false },
    { labelI18n: 'intl-msg:maintenanceHistory.filter.lastThreeYears', years: 3, default: true },
    { labelI18n: 'intl-msg:maintenanceHistory.filter.lastFiveYears', years: 5, default: false },
    { labelI18n: 'intl-msg:maintenanceHistory.filter.allServices', years: null, default: false },
];

const customDateRangeI18n = 'intl-msg:maintenanceHistory.filter.customDateRange';

const CustomDateFilter = ({ onDateChange }: CustomDateFilterProps) => {
    const intl = useIntl();
    const formattedCustomDateRange = intl.formatMessage({ id: customDateRangeI18n });

    const today = new Date();

    const getDateRange = (years: number | null) => ({
        from: years ? subYears(today, years) : new Date(2000, 0, 1),
        to: today,
    });

    const defaultRange = dateRanges.find((range) => range.default) || dateRanges[0];
    const { from: defaultFrom, to: defaultTo } = getDateRange(defaultRange.years);

    const [menuTitle, setMenuTitle] = useState(intl.formatMessage({ id: defaultRange.labelI18n }));
    const [dateFrom, setDateFrom] = useState<Date | undefined>(defaultFrom);
    const [dateTo, setDateTo] = useState<Date | undefined>(defaultTo);
    const [showDatePickers, setShowDatePickers] = useState(false);

    const dropdownItems = useMemo(() => {
        const items: MenuItemProps[] = dateRanges.map(({ labelI18n, years }) => {
            const formattedLabel = intl.formatMessage({ id: labelI18n });
            return {
                value: formattedLabel,
                onSelect: () => {
                    const { from, to } = getDateRange(years);

                    setMenuTitle(formattedLabel);
                    setDateFrom(from);
                    setDateTo(to);
                    setShowDatePickers(false);
                },
            };
        });

        items.push(
            { divider: true },
            {
                value: formattedCustomDateRange,
                onSelect: () => {
                    setMenuTitle(formattedCustomDateRange);
                    setDateFrom(undefined);
                    setDateTo(undefined);
                    setShowDatePickers(true);
                },
            },
        );

        return items;
    }, []);

    useEffect(() => {
        if (dateFrom !== undefined && dateTo !== undefined) {
            onDateChange(format(dateFrom, 'yyyy-MM-dd'), format(dateTo, 'yyyy-MM-dd'));
        }
    }, [dateFrom, dateTo]);

    return (
        <>
            <p className={'text-size-12'}>Time</p>
            <div className="row">
                <div className="col-sm-6 col-xs-6">
                    <ButtonDropdown title={menuTitle} items={dropdownItems} />
                </div>
            </div>

            {showDatePickers && (
                <div className={'row'}>
                    <div className="col-xs-12 col-ls-6 col-sm-6">
                        <div className="display-flex gap-10 max-width-400 margin-y-10">
                            <div className="flex-1-1-0">
                                <label>From:</label>
                                <DatePicker
                                    inputProps={{ placeholder: 'Select Date' }}
                                    timeFormat={false}
                                    onChange={(value, isValid) => {
                                        if (isValid) {
                                            setDateFrom((value as moment.Moment).toDate());
                                        }
                                    }}
                                    clearableInput
                                />
                            </div>
                            <div className="flex-1-1-0">
                                <label>To:</label>
                                <DatePicker
                                    inputProps={{ placeholder: 'Select Date' }}
                                    timeFormat={false}
                                    onChange={(value, isValid) => {
                                        if (isValid) {
                                            setDateTo((value as moment.Moment).toDate());
                                        }
                                    }}
                                    clearableInput
                                    alignRight
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default CustomDateFilter;
