import ContentLoader from '@rio-cloud/rio-uikit/lib/es/ContentLoader';

type LoadingRowType = {
    numCells: number;
};

export const LoadingRow = ({ numCells = 8 }: LoadingRowType) => (
    <tr>
        {[...Array(numCells)].map((_, index) => (
            <td key={index}>
                <ContentLoader />
            </td>
        ))}
    </tr>
);
