import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from '../../../configuration/tokenHandling/prepareHeaders';
import { MaintenanceHistoryList } from '../../../generated/GeneratedApiTypes';

export type MaintenanceHistoryParameters = {
    pathParameters: {
        assetId: string;
    };
    queryParameters: {
        from?: string;
        to?: string;
    };
};

export const maintenanceHistoryApi = createApi({
    reducerPath: 'maintenanceHistoryApi',
    baseQuery: fetchBaseQuery({ baseUrl: `${import.meta.env.VITE_BACKEND_URI}`, prepareHeaders }),
    endpoints: (builder) => ({
        fetchMaintenanceHistory: builder.query<MaintenanceHistoryList, MaintenanceHistoryParameters>({
            query: ({ pathParameters: { assetId }, queryParameters: { from, to } }) => {
                const queryParams = new URLSearchParams();

                if (from) queryParams.append('from', from);
                if (to) queryParams.append('to', to);

                return {
                    url: `/maintenance-history/${assetId}${queryParams.toString() ? `?${queryParams.toString()}` : ''}`,
                };
            },
        }),
    }),
});

export const { useFetchMaintenanceHistoryQuery } = maintenanceHistoryApi;
