import ErrorState from '@rio-cloud/rio-uikit/lib/es/ErrorState';
import { useIntl } from 'react-intl';

export type InternalErrorStateProps = {
    headlineI18n: string;
    messageI18n: string;
    additionalClassName?: string;
    innerClassName?: string;
    fullWidth?: boolean;
};

const InternalErrorState = (props: InternalErrorStateProps) => {
    const { headlineI18n, messageI18n, additionalClassName, innerClassName, fullWidth } = props;
    const intl = useIntl();
    return (
        <ErrorState
            headline={intl.formatMessage({ id: headlineI18n })}
            message={intl.formatMessage({ id: messageI18n })}
            // className should keep the original values plus the news values passed
            outerClassName={`${additionalClassName}`}
            innerClassName={innerClassName}
            fullWidth={fullWidth}
        />
    );
};

export default InternalErrorState;
