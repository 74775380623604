import { createHashRouter, createRoutesFromElements, Route } from 'react-router-dom';
import App from '../features/app/App';
import { ErrorBoundary } from '../features/app/ErrorBoundary';
import React from 'react';
import MaintenanceHistoryTable from '../features/history/MaintenanceHistoryTable';

export const ASSET_ID_ROUTE = '/:assetId';
export const DEFAULT_ROUTE = '/error';
export const routes = [ASSET_ID_ROUTE, DEFAULT_ROUTE];

export const router = createHashRouter(
    createRoutesFromElements(
        <Route path="/" element={<App />}>
            <Route path={ASSET_ID_ROUTE} errorElement={<ErrorBoundary />} element={<MaintenanceHistoryTable />} />
        </Route>,
    ),
);
